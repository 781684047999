<template>
  <div class="live-square">
    <!-- 搜索区域 -->
    <SearchWrap>
      <el-form v-model="search" inline label-suffix="：">
        <el-form-item label="厂家名称" prop="factoryName">
          <el-input v-model.trim="search.factoryName" placeholder="请输入厂家名称" clearable />
        </el-form-item>
        <el-form-item label="直播标题" prop="title">
          <el-input v-model.trim="search.title" placeholder="请输入直播标题" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="queryData"> 搜索 </el-button>
          <el-button type="primary" plain @click="addVideoDialogVisible = true"> 上传视频 </el-button>
          <el-button type="primary" @click="auditDialog = true"> 审核 </el-button>
        </el-form-item>
      </el-form>
    </SearchWrap>
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column prop="liveId" label="直播ID" />
      <el-table-column prop="title" label="直播标题" />
      <el-table-column prop="factoryName" label="厂家名" />
      <el-table-column prop="updatedAt" width="200" label="更新时间">
        <template v-if="scoped.row.updatedAt" slot-scope="scoped">
          {{ formatDate(scoped.row.updatedAt) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="scoped">
          <el-button
            type="success"
            size="mini"
            plain
            @click="onPlayer(scoped.row)"
          >
            播放
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
    <el-dialog :title="activeItem.title" :visible.sync="dialogVisible" width="60%">
      <div class="live-player">
        <LivePlayer v-if="dialogVisible" type="live" :live-url="activeItem.playAddr" />
      </div>
      <div slot="footer" style="text-align: center;">
        <el-button @click="dialogVisible = false"> 关闭 </el-button>
      </div>
    </el-dialog>

    <!-- 上传视频 -->
    <UploadLiveVideo v-model="addVideoDialogVisible" />
    <!-- 审核医生讲者 -->
    <audit v-model="auditDialog" />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { getLiveSquare } from '@/apis/liveSquare'
import LivePlayer from './components/LivePlayer/index.vue'
import UploadLiveVideo from './components/upload-live-video.vue'
import Audit from './components/audit.vue'
export default {
  name: 'LiveSquare',
  components: { LivePlayer, UploadLiveVideo, Audit },
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      search: {
        factoryName: '',
        title: ''
      },
      tableData: [],
      activeItem: {},
      dialogVisible: false,
      addVideoDialogVisible: false,
      auditDialog: false
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      try {
        this.loading = true
        const params = this.getParams(this.search)
        const { data, totalCount } = await getLiveSquare(params)
        this.loading = false
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    queryData() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    onPlayer(row) {
      this.activeItem = row
      this.dialogVisible = true
    },
    handleCopyLink(row, type) {
      const _this = this
      let copyText = ''
      if (type == 'checkResult') {
        copyText = row[type].split('：')[1]
      } else {
        copyText = row[type]
      }
      this.$copyText(copyText).then(
        function() {
          _this.$message({
            message: `复制成功${type}: ${copyText}`,
            type: 'success'
          })
        },
        function() {
          _this.$message({
            showClose: true,
            message: '复制失败，请手动复制',
            type: 'error'
          })
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.live-square {
  .live-player {
    width: 100%;
    height: 500px;
  }
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>

// scss
.live-square {
    .live-player {
        width: 100%;
        height: 500px;
    }
    .pagination-wrap {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}
