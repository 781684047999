<template>
  <div class="dplayer-wrapper">
    <div id="dplayer" />
    <slot
      name="cover"
      :playEnd="loadError"
    />
  </div>
</template>

<script>
import DPlayer from 'dplayer'
import Hls from 'hls.js'
import flvjs from 'flv.js'

import flvPlayer from './flvPlayer'
import hlsPlayer from './hlsPlayer'
import mp4Player from './mp4Player'

import { judgeClient } from '@/utils/util'

export default {
  name: 'LivePlayer',
  mixins: [flvPlayer, hlsPlayer, mp4Player],
  props: {
    type: {
      type: String,
      default: 'live'
    },
    liveUrl: {
      type: Array,
      default: Array
    }
  },
  data() {
    return {
      dp: null,
      loadError: true,
      timer: null,
      defaultOptions: {
        container: '',
        autoplay: false,
        live: false,
        preload: false,
        loop: false,
        video: {
          defaultQuality: 0
        },
        pluginOptions: {}
      }
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.timer = null
    if (this.type === 'playback') {
      console.log('playback')
    } else if (this.type === 'live') {
      if (this.videoFormat('flv')) {
        this.unInstallFlvPlayerEvent()
      } else if (this.videoFormat('m3u8')) {
        this.unInstallHlsEvent()
      }
    }
    this.dp.destroy()
  },
  methods: {
    init() {
      this.defaultOptions.container = document.getElementById('dplayer')
      if (this.type === 'playback') {
        this.defaultOptions.live = false
        this.initVideo(this.defaultOptions, this.initDplayer)
      } else if (this.type === 'live') {
        this.defaultOptions.live = true
        if (this.videoFormat('flv')) {
          this.initFlvPlayer(this.defaultOptions, this.initDplayer)
        } else if (this.videoFormat('m3u8')) {
          this.initHls(this.defaultOptions, this.initDplayer)
        }
      }
      this.registerDplayerEvent()
    },
    initDplayer(defaultOptions = this.defaultOptions) {
      this.dp = new DPlayer(defaultOptions)
    },
    registerDplayerEvent() {
      // 视频初始化完成，可以播放
      this.dp.on('loadedmetadata', () => {
        console.log('loadedmetadata')
        clearTimeout(this.timer)
        this.timer = null
        this.loadError = false
        // this.dp.contextmenu.show()
        this.isOnce++
      })
      this.dp.on('canplaythrough', () => {
        this.loadError = false
        this.dp.contextmenu.show()
        console.log('canplaythrough')
        this.dp.play()
      })
      // 视频正在播放
      this.dp.on('timeupdate', () => {
        this.$emit('timeupdate')
      })
      this.dp.on('ended', () => {
        if (this.type === 'playback') {
          console.log('playback')
        } else if (this.type === 'live') {
          if (this.videoFormat('flv')) {
            this.flvPlayer.unload()
            this.flvPlayer.load()
          } else if (this.videoFormat('m3u8')) {
            // this.unInstallHlsEvent()
          }
        }
        console.log('ended')
        this.$emit('ended')
      })
      this.dp.on('play', () => {
        this.$emit('play')
      })
      this.dp.on('pause', () => {
        this.$emit('pause')
      })
      this.dp.on('error', () => {
        console.log('dp-error')
        this.$emit('error')
      })
    },
    // 判断播放格式
    videoFormat(type) {
      if (type === 'flv') {
        //  && !navigator.userAgent.indexOf('Safari') > -1
        return !!flvjs.isSupported()
      } else if (type === 'm3u8') {
        return !!Hls.isSupported() || judgeClient() === 'IOS'
      }
    },

    filterUrl(type) {
      let url = ''
      this.liveUrl.forEach(item => {
        if (item.indexOf(type) !== -1) {
          url = item
        }
      })
      return url
    }
  }
}
</script>

<style lang="scss" scoped>
.dplayer-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  :deep(.dplayer) {
    width: 100%;
    height: 100%;
    .dplayer-menu.dplayer-menu-show,
    .dplayer-quality,
    .dplayer-setting-loop,
    .dplayer-full-in-icon {
      display: none !important;
    }
  }
}
</style>
