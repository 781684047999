export default {
  props: {
    videoUrl: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      definition: {
        sd: '标清',
        hd: '高清'
      }
    }
  },
  methods: {
    initVideo(defaultOptions = this.defaultOptions, fun) {
      defaultOptions.video.quality = []
      for (const key in this.videoUrl) {
        for (const childrenKey in this.videoUrl[key]) {
          defaultOptions.video.quality.push({
            name: this.definition[childrenKey],
            url: this.videoUrl[key][childrenKey],
            type: key
          })
        }
      }
      this.defaultOptions.video.quality = defaultOptions.video.quality.filter(item => item.url)
      fun()
    }
  }
}