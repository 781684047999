import request from '@/utils/request'
import API from './api-types'

export function getLiveSquare(params) {
  return request({
    url: API.getLiveSquare,
    method: 'get',
    params
  })
}

export function verifyLiveId(params) {
  return request({
    url: API.verifyLiveId,
    method: 'get',
    params
  })
}

export function uploadVideo(params) {
  return request({
    url: API.uploadVideo,
    method: 'get',
    params
  })
}

export function rollbackLiveSpeaker(params) {
  return request({
    url: API.rollbackLiveSpeaker,
    method: 'get',
    params
  })
}
export function creditFail(params) {
  return request({
    url: API.creditFail,
    method: 'get',
    params
  })
}
