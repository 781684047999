<template>
  <el-dialog
    title="审核"
    :visible.sync="auditDialog"
    width="730px"
    @closed="closed"
  >
    <el-tabs
      v-model="activeName"
      class="tabs"
    >
      <el-tab-pane
        label="医生审核退回"
        name="first"
      >
        <el-form
          label-suffix="："
          label-width="100px"
          :model="rollbackForm"
        >
          <el-form-item label="doctorId">
            <el-input
              v-model="rollbackForm.doctorId"
              placeholder="请输入doctorId"
            />
          </el-form-item>
          <el-form-item label="">
            <el-button
              :loading="rollbackForm.loading"
              @click="creditFailFn"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane
        label="讲者审核退回"
        name="second"
      >
        <el-form
          label-suffix="："
          label-width="100px"
          :model="rollbackForm"
        >
          <el-form-item label="userID">
            <el-input
              v-model="rollbackForm.userId"
              placeholder="请输入userID"
            />
          </el-form-item>
          <el-form-item label="直播ID">
            <el-input
              v-model="rollbackForm.liveId"
              placeholder="请输入直播ID"
            />
          </el-form-item>
          <el-form-item label="">
            <el-button
              :loading="rollbackForm.loading"
              @click="rollbackLiveSpeakerFn"
            >
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import { rollbackLiveSpeaker, creditFail } from '@/apis/liveSquare'
export default {
  name: 'Audit',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeName: 'first',
      rollbackForm: {
        liveId: '',
        userId: '',
        doctorId: '',
        loading: false
      }
    }
  },
  computed: {
    auditDialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {},
  methods: {
    async rollbackLiveSpeakerFn() {
      const { liveId, userId } = this.rollbackForm
      if (!liveId || !userId) {
        this.$message.error('请输入直播ID和userId')
        return false
      }
      try {
        this.rollbackForm.loading = true
        rollbackLiveSpeaker
        const res = await rollbackLiveSpeaker({ liveId, userId })
        console.log(res)
        this.$message.success('提交成功')
        this.rollbackForm.loading = false
      } catch (error) {
        console.log(error)
        this.rollbackForm.loading = false
      }
    },
    async creditFailFn() {
      const { doctorId } = this.rollbackForm
      if (!doctorId) {
        this.$message.error('请输入doctorId')
        return false
      }
      try {
        this.rollbackForm.loading = true
        const res = await creditFail({ doctorId })
        console.log(res)
        this.$message.success('提交成功')
        this.rollbackForm.loading = false
      } catch (error) {
        console.log(error)
        this.rollbackForm.loading = false
      }
    },
    closed() {
      this.rollbackForm = this.$options.data().rollbackForm
    }
  }
}
</script>

<style scoped lang="scss">
// scss
:deep(.el-dialog__body) {
  padding-top: 0;
}
</style>
