<template>
  <el-dialog
    title="上传视频"
    destroy-on-close
    :visible.sync="addVideoDialogVisible"
    width="730px"
  >
    <p style="margin-top: 0; color: #008075; margin-left: 100px;">请先校验ID，在上传视频</p>
    <el-form label-suffix="：" label-width="100px" :model="addVidoeForm">
      <el-row :gutter="10">
        <el-form-item label="直播ID">
          <el-col :span="19">
            <el-input v-model="addVidoeForm.liveId" :disabled="addVidoeForm.getSignature !== ''" placeholder="请输入直播ID" />
          </el-col>
          <el-col :span="4">
            <el-button @click="verifyID"> 校验ID </el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="上传视频">
          <el-col :span="24">
            <FileUpload
              ref="upload"
              :on-remove="handleRemove"
              :size="800"
              :max="1"
              :ext="['mp4']"
              action=""
              :auto-upload="true"
              :http-request="customUpload"
              :before-upload-verify="beforeUpload"
            />
          </el-col>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取 消</el-button>
      <el-button type="primary" :loading="addVidoeForm.loading" @click="onSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TcVod from 'vod-js-sdk-v6'
import { verifyLiveId, uploadVideo } from '@/apis/liveSquare'
export default {
  name: 'UploadLiveVideo',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addVidoeForm: {
        liveId: '',
        getSignature: '',
        loading: false
      }
    }
  },
  computed: {
    addVideoDialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {},
  methods: {
    // 上传直播视频
    async customUpload(file) {
      const tcVod = new TcVod({
        getSignature: () => this.addVidoeForm.getSignature
      })
      const uploader = tcVod.upload({
        mediaFile: file.file // 媒体文件（视频或音频或图片），类型为 File
      })
      uploader.on('media_progress', function(info) {
        file.onProgress({ percent: info.percent * 100 })
      })
      try {
        const doneResult = await uploader.done()
        console.log(doneResult)
        this.addVidoeForm.fileId = doneResult.fileId
        file.onSuccess() // 上传成功(打钩的小图标)
        // deal with doneResult
      } catch (error) {
        // deal with error
        file.onError()
        console.log(error)
      }
    },
    // 验证ID
    async verifyID() {
      if (!this.addVidoeForm.liveId) {
        this.$message.error('请输入直播ID')
        return false
      }
      try {
        const res = await verifyLiveId({ liveId: this.addVidoeForm.liveId })
        this.addVidoeForm.getSignature = res
        this.$message.success('校验成功，可以上传视频了')
      } catch (error) {
        console.log(error)
      }
    },
    beforeUpload() {
      if (!this.addVidoeForm.liveId) {
        this.$message.error('请先校验直播ID')
        return false
      }
      return true
    },
    /** 移除上传文件 **/
    handleRemove() {
      this.$refs.upload.$refs.upload.abort() // 取消上传
    },
    async onSubmit() {
      this.addVidoeForm.loading = true
      const { liveId, fileId } = this.addVidoeForm
      if (!liveId) {
        this.$message.error('请填写直播ID')
        return false
      }
      if (!fileId) {
        this.$message.error('请上传视频')
        return false
      }
      try {
        const res = await uploadVideo({ liveId, fileId })
        console.log('%c [ res ]-318', 'font-size:14px; background:#67c23a; color:#ffffff;', res)
        this.addVideoDialogVisible = false
        this.addVidoeForm.loading = false
        this.$message.success('添加成功')
        this.closed()
      } catch (error) {
        this.addVidoeForm.loading = false
        console.log(error)
      }
    },
    onCancel() {
      this.addVideoDialogVisible = false
      this.closed()
    },
    closed() {
      this.addVidoeForm = this.$options.data().addVidoeForm
      console.log(this.addVidoeForm)
    }
  }
}
</script>
